export const GetCountrys = "GET_COUNTRYS";
export const GetOrgDetails = "GET_ORG_DETAILS";
export const GetPlanDetails = "GET_PLAN_DETAILS";
export const GetCampaignDetails = "GET_CAMPAIGN_DETAILS";
export const GetSubuserDetails = "GET_SUBUSER_DETAILS";
export const GetPaymentDetails = "GET_PAYMENT_DETAILS";
export const GetCampaignList = "GET_CAMPAIGN_LIST";
export const GetSubuserList = "GET_SUBUSER_LIST";
export const GetPaymentList = "GET_PAYMENT_LIST";
export const GetPlanWiseUsers = "GET_PLAN_WISE_USERS";
export const GetPlansList = "GET_PLANS_LIST";
export const GetTenants = "GET_TENANT_LIST";
export const GetPlan = "GET_PLAN";
export const ClearOrgDetails = "CLEAR_ORG_DETAILS";
