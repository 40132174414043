import React from "react";

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getTenantList } from "action/plans";
import { cyanBlue } from "pages/Details/constants/Constants";
import CustomPagination from "pages/Details/components/Pagination";
import FilterOptions from "./FilterOptions";
import { changeTenantStatus } from "action/plans";
import Swal from "sweetalert2";
import { getPlanWiseUsers } from "action/plans";

function Tenants() {
  const sortParams = `&sortOptionJSON={"sortBy":"ID","sortType":"desc"}`;
  const dispatch = useDispatch();
  const [pageNum, setPageNum] = React.useState(1);
  const [tabValue, setTabValue] = React.useState("Active_Tenants");
  const [isLoading, setIsLoading] = React.useState(true);
  const tenantData = useSelector(
    (state) => state.TenantReducer.tenantList.data
  );
  const plans = useSelector((state) => state.planReducer.plansTenantList);

  const [queryString, setQueryString] = React.useState("");
  const [searchData, setSearchData] = React.useState({
    planName: "",
    status: "",
    countryName: "",
    startDate: "",
    endDate: "",
    email: "",
    name: "",
  });

  React.useEffect(() => {
    getPlanWiseUsers(dispatch);
  }, [dispatch]);

  const handleTabChange = (e, value) => {
    setIsLoading(true);
    setTabValue(value);
    setPageNum(1);
    setSearchData({
      planName: "",
      status: "",
      countryName: "",
      startDate: "",
      endDate: "",
      email: "",
      name: "",
    });
    setQueryString("");
  };

  React.useEffect(() => {
    if (tenantData) setIsLoading(false);
  }, [tenantData]);

  React.useEffect(() => {
    if (queryString === "") {
      let query;
      if (tabValue === "Active_Tenants") {
        query = [
          {
            field: "status",
            condition: "eq",
            filterValues: ["ACTIVE"],
          },
        ];
      } else {
        query = [
          {
            field: "status",
            condition: "notin",
            filterValues: ["ACTIVE"],
          },
        ];
      }
      setQueryString(JSON.stringify(query));
    }
    getTenantList(dispatch, queryString, pageNum, sortParams);
  }, [dispatch, queryString, tabValue, pageNum]);

  const handleStatus = (cell, row) => {
    switch (cell) {
      case "ACTIVE": {
        return (
          <div>
            <i
              className="fa-solid fa-user-check text-success "
              aria-hidden="true"
              id={`tooltip${row.ID}`}
            ></i>
            <UncontrolledTooltip target={`tooltip${row.ID}`}>
              Active
            </UncontrolledTooltip>
          </div>
        );
      }
      case "INACTIVE": {
        return (
          <div>
            <i
              id={`tooltip${row.ID}`}
              className="fa-solid fa-user-xmark text-danger "
            ></i>
            <UncontrolledTooltip target={`tooltip${row.ID}`}>
              Inactive
            </UncontrolledTooltip>
          </div>
        );
      }
      case "NOT_VERIFIED": {
        return (
          <div>
            <i
              id={`tooltip${row.ID}`}
              className="fa-solid fa-user-slash text-warning "
            ></i>
            <UncontrolledTooltip target={`tooltip${row.ID}`}>
              Not Verified
            </UncontrolledTooltip>
          </div>
        );
      }
      case "DELETED": {
        return (
          <div>
            <i
              id={`tooltip${row.ID}`}
              className="fa-solid fa-user-minus text-danger"
            ></i>
            <UncontrolledTooltip target={`tooltip${row.ID}`}>
              Deleted
            </UncontrolledTooltip>
          </div>
        );
      }
      default:
        return cell;
    }
  };

  const handleCountry = (value) => {
    if (value?.name) return value.name;
    else return <div className="ml-3">-</div>;
  };

  //To change the status of Tenant
  const handleTenantStatus = async (row) => {
    Swal.fire({
      text: `Are you sure you want to delete "${
        row.firstName ? row.firstName + row.lastName : row.email
      }" ?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      icon: "warning",
      // focusConfirm: false,
      focusCancel: true,
      customClass: {
        confirmButton: "bg-info",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStatus(row);
      }
    });
  };
  const deleteStatus = async (row) => {
    let tempQuery = JSON.stringify([
      {
        field: "status",
        condition: "eq",
        filterValues: ["ACTIVE"],
      },
    ]);
    const res = await changeTenantStatus(row.ID);
    if (res.code === 200) {
      getTenantList(dispatch, tempQuery, pageNum, sortParams);
      Swal.fire({
        title: `"${
          row.firstName ? row.firstName + row.lastName : row.email
        }" is deleted successfully.`,
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      getTenantList(dispatch, tempQuery, pageNum, sortParams);
      Swal.fire({
        title: "Something Went Wrong!",
        text: `Couldn't delete "${
          row.firstName ? row.firstName + row.lastName : row.email
        }".`,
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
      });
    }
  };

  // const handleCountry = (value) => {
  //   if (value?.name) return value.name;
  //   else return <div className="ml-3">-</div>;
  // };

  const handlePlanChange = (selectedOption) => {
    setSearchData((prevData) => ({
      ...prevData,
      planName: selectedOption?.value || "",
    }));
  };

  const handleStatusChange = (selectedOption) => {
    setSearchData((prevData) => ({
      ...prevData,
      status: selectedOption?.value || "",
    }));
  };

  const handleNameChange = (input) => {
    setSearchData((prev) => ({ ...prev, name: input.target.value }));
  };

  const handleEmailChange = (input) => {
    setSearchData((prev) => ({ ...prev, email: input.target.value }));
  };

  const handleCountryNameChange = (selectedOption) => {
    setSearchData((prevData) => ({
      ...prevData,
      countryName: selectedOption?.value || "",
    }));
  };

  const handleStartDateChange = (e) => {
    setSearchData((prevData) => ({ ...prevData, startDate: e.target.value }));
    if (
      searchData.endDate &&
      new Date(searchData.endDate) < new Date(e.target.value)
    ) {
      setSearchData((prevData) => ({ ...prevData, endDate: "" }));
    }
  };

  const handleEndDateChange = (e) => {
    setSearchData((prevData) => ({ ...prevData, endDate: e.target.value }));
  };

  const handleSearch = () => {
    const query = Object.keys(searchData)
      .map((key) => {
        if (key === "startDate" || key === "endDate") {
          return null;
        } else if (searchData[key]) {
          return {
            field: key,
            condition: key !== "status" ? "like" : "eq",
            filterValues: [searchData[key]],
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    if (tabValue === "Active_Tenants") {
      query.push({
        field: "status",
        condition: "eq",
        filterValues: ["ACTIVE"],
      });
    } else {
      query.push({
        field: "status",
        condition: "notin",
        filterValues: ["ACTIVE"],
      });
    }
    if (searchData.startDate && searchData.endDate) {
      query.push({
        field: "registeredDate",
        condition: "btw",
        filterValues: [searchData.startDate, searchData.endDate],
      });
    } else if (searchData.startDate && !searchData.endDate) {
      query.push({
        field: "registeredDate",
        condition: "gt",
        filterValues: [searchData.startDate],
      });
    } else if (!searchData.startDate && searchData.endDate) {
      query.push({
        field: "registeredDate",
        condition: "lt",
        filterValues: [searchData.endDate],
      });
    }
    if (queryString !== JSON.stringify(query)) setIsLoading(true);

    setQueryString(JSON.stringify(query));
    setPageNum(1);
    // getTenantList(dispatch, JSON.stringify(query), pageNum, sortParams);
  };

  const handleClear = () => {
    setSearchData({
      planName: "",
      status: "",
      countryName: "",
      startDate: "",
      endDate: "",
      email: "",
      name: "",
    });
    setIsLoading(true);
    setPageNum(1);
    setQueryString("");
    // let query;
    // if (tabValue === "Active_Tenants") {
    //   query = [
    //     {
    //       field: "status",
    //       condition: "eq",
    //       filterValues: ["ACTIVE"],
    //     },
    //   ];
    // } else {
    //   query = [
    //     {
    //       field: "status",
    //       condition: "notin",
    //       filterValues: ["ACTIVE"],
    //     },
    //   ];
    // }
    // getTenantList(dispatch, JSON.stringify(query), pageNum, sortParams);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <>
      <SimpleHeader name="Tenants" />
      <Container fluid className="mt--6">
        <Card>
          <CardBody>
            <div md={12} style={styles.header}>
              <h3 className="mb-0">Tenants</h3>
            </div>
            <Col lg="12" md="12" sm="12" className="my-3">
              <FilterOptions
                tabValue={tabValue}
                pageNum={pageNum}
                searchData={searchData}
                handlePlanChange={handlePlanChange}
                handleClear={handleClear}
                handleStatusChange={handleStatusChange}
                handleCountryNameChange={handleCountryNameChange}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                handleSearch={handleSearch}
                handleEmailChange={handleEmailChange}
                handleNameChange={handleNameChange}
                handleKeyPress={handleKeyPress}
              />
            </Col>
            <Row lg="12" md="12" sm="12">
              <Col lg="12" md="12" sm="12">
                <Tabs
                  variant="fullWidth"
                  className="border-bottom"
                  value={tabValue}
                  onChange={handleTabChange}
                >
                  <Tab label="Active Tenants" value="Active_Tenants" />
                  <Tab label="Archive Tenants" value="Archive_Tenants" />
                </Tabs>
                {isLoading ? (
                  <div style={styles.loaderContainer}>
                    <i
                      className="fa fa-spinner fa-spin fa-2xl"
                      style={styles.loaderColor}
                    />
                  </div>
                ) : (
                  <>
                    {tenantData?.code ? (
                      <div style={styles.loaderContainer}>
                        Something went wrong
                      </div>
                    ) : tenantData.tenants ? (
                      <>
                        <ToolkitProvider
                          data={tenantData?.tenants ? tenantData.tenants : []}
                          keyField="ID"
                          columns={[
                            {
                              dataField: "organizationName",
                              text: "Organization",
                              sort: false,
                              formatter: (cell) => {
                                if (cell === "")
                                  return (
                                    <div className="text-center h-0">- </div>
                                  );
                                else return cell;
                              },
                            },
                            {
                              dataField: "firstName",
                              text: "Name",
                              sort: false,
                              formatter: (cell, row) => {
                                if (row.firstName === "" && row.lastName === "")
                                  return (
                                    <div className="text-center h-0">- </div>
                                  );
                                else
                                  return row.firstName.concat(
                                    " ",
                                    row.lastName
                                  );
                              },
                            },
                            {
                              dataField: "email",
                              text: "Email",
                              sort: false,
                              formatter: (cell) => {
                                if (cell === "")
                                  return (
                                    <div className="text-center h-0">- </div>
                                  );
                                else return cell;
                              },
                            },
                            {
                              dataField: "plan",
                              text: "Plan",
                              sort: true,
                              formatter: (cell) => {
                                if (cell === "")
                                  return (
                                    <div className="text-center h-0">- </div>
                                  );
                                else return cell;
                              },
                            },
                            {
                              dataField: "registeredDate",
                              text: "Registered Date",
                              sort: true,
                              formatter: (cell) => {
                                if (cell === "")
                                  return (
                                    <div className="text-center h-0">- </div>
                                  );
                                else return cell;
                              },
                            },
                            {
                              dataField: "countryResponse",
                              text: "Country",
                              sort: true,
                              formatter: (cell) => handleCountry(cell),
                            },
                            {
                              dataField: "totalCampaign",
                              text: "Total Campaign",
                              sort: true,
                              formatter: (cell) => {
                                if (cell === "")
                                  return (
                                    <div className="text-center h-0">- </div>
                                  );
                                else return cell;
                              },
                            },
                            {
                              dataField: "creditSpent",
                              text: "Credits Spent",
                              sort: true,
                              formatter: (cell) => {
                                if (cell === "")
                                  return (
                                    <div className="text-center h-0">- </div>
                                  );
                                else return cell;
                              },
                            },
                            {
                              dataField: "subUsers",
                              text: "Sub Users",
                              sort: true,
                              formatter: (cell) => {
                                if (cell === "")
                                  return (
                                    <div className="text-center h-0">- </div>
                                  );
                                else return cell;
                              },
                            },
                            {
                              dataField: "status",
                              text: "Status",
                              sort: true,
                              align: "center",
                              formatter: (cell, row) => {
                                return handleStatus(cell, row);
                              },
                            },
                            tabValue === "Active_Tenants" && {
                              dataField: "ID",
                              text: "Actions",
                              // sort: true,
                              align: "center",
                              formatter: (cell, row) => (
                                <div>
                                  <a
                                    className="table-action table-action-delete"
                                    id={`tooltipDelete${cell}`}
                                    onClick={() => {
                                      handleTenantStatus(row);
                                    }}
                                  >
                                    <i className="fas fa-trash" />
                                  </a>
                                  <UncontrolledTooltip
                                    target={`tooltipDelete${cell}`}
                                  >
                                    Delete Tenant
                                  </UncontrolledTooltip>
                                </div>
                              ),
                            },
                          ]}
                          search
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                        <CustomPagination
                          totalPages={Math.ceil(
                            tenantData.totalRecords /
                              tenantData.noOfRecordsPerPage
                          )}
                          setPageNum={setPageNum}
                          activePage={pageNum}
                        />
                      </>
                    ) : (
                      <div style={styles.loaderContainer}>No Records Found</div>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default Tenants;
const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
};
