import * as api from "../API/index";
import {
  GetTenants,
  GetPlan,
  GetPlansList,
  GetCountrys,
  GetOrgDetails,
  GetPlanDetails,
  GetCampaignList,
  GetSubuserList,
  GetPaymentList,
  GetPlanWiseUsers,
} from "constants/ReducerConstants/Constants";

export const addPlan = async (planData) => {
  try {
    const res = await api.add_plan(planData);
  } catch (error) {
    console.log(error);
  }
};

export const getPlan = async (planId, dispatch) => {
  try {
    const res = await api.get_plan(planId);
    dispatch({ type: GetPlan, payload: res.data });
  } catch (error) {
    dispatch({ type: GetPlan, payload: error.response.data });
  }
};

export const getPlansList = async (dispatch) => {
  try {
    const res = await api.get_plans_list();
    dispatch({ type: GetPlansList, payload: res.data.data });
  } catch (error) {
    dispatch({ type: GetPlansList, payload: error.response.data });
  }
};

export const getTenantList = async (dispatch, query, num, sort) => {
  try {
    const res = await api.get_tenant_list(query, num, sort);
    dispatch({ type: GetTenants, payload: res.data });
  } catch (error) {
    dispatch({ type: GetTenants, payload: error });
  }
};

export const updatePlan = async (planId, planData) => {
  try {
    const res = await api.update_plan(planId, planData);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getCountryList = async (dispatch) => {
  try {
    const res = await api.get_country_list();
    dispatch({ type: GetCountrys, payload: res.data });
  } catch (error) {
    return error;
  }
};

export const getTenantDetails = async (dispatch, id) => {
  try {
    const res = await api.get_tenant_details(id);
    dispatch({ type: GetOrgDetails, payload: res.data });
  } catch (error) {
    dispatch({ type: GetOrgDetails, payload: error });
  }
};

export const getTenantPlanDetails = async (dispatch, id) => {
  try {
    const res = await api.get_tenant_plan_details(id);
    dispatch({ type: GetPlanDetails, payload: res.data });
  } catch (error) {
    return error;
  }
};

export const getCampaignList = async (dispatch, id, filter, pageNum, sort) => {
  try {
    const res = await api.get_campaign_list(id, filter, pageNum, sort);
    dispatch({ type: GetCampaignList, payload: res.data.data });
  } catch (error) {
    return error;
  }
};

export const getSubuserList = async (dispatch, id, filter, pageNum, sort) => {
  try {
    const res = await api.get_subuser_list(id, filter, pageNum, sort);
    dispatch({ type: GetSubuserList, payload: res.data.data });
  } catch (error) {
    return error;
  }
};

export const getPaymentList = async (dispatch, id, filter, pageNum, sort) => {
  try {
    const res = await api.get_payment_list(id, filter, pageNum, sort);
    dispatch({ type: GetPaymentList, payload: res.data.data });
  } catch (error) {
    return error;
  }
};

export const getPlanWiseUsers = async (dispatch) => {
  try {
    const res = await api.get_plan_wise_users();
    dispatch({ type: GetPlanWiseUsers, payload: res.data });
  } catch (error) {
    dispatch({ type: GetPlanWiseUsers, payload: error.response.data });
  }
};

export const changeTenantStatus = async (id) => {
  try {
    const res = await api.change_tenant_status(id);
    return res.data;
  } catch (error) {
    return error;
  }
};
