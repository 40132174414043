import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  Modal,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { addPlan } from "action/plans";


function CreatePlan() {
  // States for all fields
  const [configData, setConfigData] = React.useState({
    name: "",
    type: "",
    credits: 0,
    subUsersLimit:0,
    usdRate:0,
    inRate:0,
  });

  const [errors, setErrors] = React.useState({});

 
  // Inline styles
  const styles = {
    formInstructions: {
      paddingRight: "15px",
    },
    formField: {
      marginBottom: "1rem",
      display: "flex",
      alignItems: "center",
    },
    label: {
      width: "200px",
      textAlign: "left",
      marginRight: "1rem",
    },
    input: {
      flex: 1,
    },
    videoContainer: {
      width: "70%", // Adjust the width as needed
      maxWidth: "1200px", // Set a maximum width
      height: "80vh", // Adjust the height as needed
      maxHeight: "800px", // Set a maximum height
    },
    infoContainer: {
      border: "1px solid #ddd",
      borderRadius: "4px",
      marginTop: "20px",
      backgroundColor: "#fff", // White background for container
    },
    heading: {
      backgroundColor: "#f6f9fc", // Background color for note content
      padding: "10px", // Adjust padding for better visual separation
      borderRadius: "4px", // Optional: Add border radius for rounded corners
    },
    contain: {
      padding: "8px",
    },
    underline: {
      border: "0",
      borderTop: "1px solid #ddd", // Color of the underline
      margin: "1px 0", // Space around the underline
    },
    highlight: {
      color: "blue", // Blue color for "Click here"
      textDecoration: "underline", // Optional: Adds underline for emphasis
      cursor: "pointer", // Optional: Changes cursor to pointer for a link-like effect
    },
    inputContainer: {
      width: "80%",
    },
    errorContainer: {
      color: "red", // Optional: Adjust color of the error message
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfigData({ ...configData, [name]: value });
    handleBlur(e);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "")
      setErrors({ ...errors, [name]: "This field is required." });
    else setErrors({ ...errors, [name]: "" });
  };

  const submitData=(e)=>{
 e.preventDefault()
 addPlan(configData)
  }
  return (
    <>
      <SimpleHeader name="Company Name" parentName="Settings" />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Add Plan</h3>
              </CardHeader>
              <CardBody>
                <Form className="needs-validation" noValidate>
                  <Row>
                    <Col md="9">
                      <FormGroup style={styles.formField}>
                        <Label for="name" style={styles.label}>
                         Plan Name
                        </Label>
                        <div style={styles.inputContainer}>
                          <Input
                          placeholder="Enter plan name"
                            type="text"
                            id="name"
                            name="name"
                            value={configData.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={styles.input}
                          />
                           
                          <div style={styles.errorContainer}>
                            {errors.name && (
                              <small className="text-danger">
                                {errors.name}
                              </small>
                            )}
                          </div>
                        </div>
                      </FormGroup>
                   
                      
                      <FormGroup style={styles.formField}>
                        <Label for="type" style={styles.label}>
                          Plan Type
                        </Label>
                        <div style={styles.inputContainer}>
                          <Input
                            type="select"
                            id="type"
                            className={errors.type ? "is-invalid" : ""}
                            value={configData.type}
                            name="type"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={styles.input}
                            placeholder="Enter sender name"
                          >
                             <option>RECURRING</option>
                             <option>ONE_TIME_PLAN</option>
                             <option>CUSTOM</option>
                          </Input>
                          <div style={styles.errorContainer}>
                            {errors.type && (
                              <small className="text-danger">
                                {errors.type}
                              </small>
                            )}
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup style={styles.formField}>
                        <Label for="credits" style={styles.label}>
                          Credits
                        </Label>
                        <div style={styles.inputContainer}>
                          <Input
                            type="credits"
                            id="credits"
                            name="credits"
                            className={errors.credits ? "is-invalid" : ""}
                            value={configData.credits || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={styles.input}
                            placeholder="Enter credits"
                          />
                          <div style={styles.errorContainer}>
                            {errors.credits && (
                              <small className="text-danger">
                                {errors.credits}
                              </small>
                            )}
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup style={styles.formField}>
                        <Label for="subUsersLimit" style={styles.label}>
                          Sub User Limit
                        </Label>
                        <div style={styles.inputContainer}>
                          <Input
                            type="subUsersLimit"
                            id="subUsersLimit"
                            name="subUsersLimit"
                            className={errors.subUsersLimit ? "is-invalid" : ""}
                            value={configData.subUsersLimit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={styles.input}
                            placeholder="Enter subUsersLimit"
                          />
                          <div style={styles.errorContainer}>
                            {errors.subUsersLimit && (
                              <small className="text-danger">
                                {errors.subUsersLimit}
                              </small>
                            )}
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup style={styles.formField}>
                        <Label for="usdRate" style={styles.label}>
                          USD Rate
                        </Label>
                        <div style={styles.inputContainer}>
                          <Input
                            type="usdRate"
                            id="usdRate"
                            name="usdRate"
                            className={errors.usdRate ? "is-invalid" : ""}
                            value={configData.usdRate || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={styles.input}
                            placeholder="Enter usdRate"
                          />
                          <div style={styles.errorContainer}>
                            {errors.usdRate && (
                              <small className="text-danger">
                                {errors.usdRate}
                              </small>
                            )}
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup style={styles.formField}>
                        <Label for="inRate" style={styles.label}>
                          Indian Rate
                        </Label>
                        <div style={styles.inputContainer}>
                          <Input
                            type="inRate"
                            id="inRate"
                            name="inRate"
                            className={errors.inRate ? "is-invalid" : ""}
                            value={configData.inRate || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={styles.input}
                            placeholder="Enter indian rate"
                          />
                          <div style={styles.errorContainer}>
                            {errors.inRate && (
                              <small className="text-danger">
                                {errors.inRate}
                              </small>
                            )}
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup style={styles.formField}>
                        <Label for="status" style={styles.label}>
                         Status
                        </Label>
                        <div style={styles.inputContainer}>
                          <Input
                            type="select"
                            id="status"
                            name="status"
                            className={errors.status ? "is-invalid" : ""}
                            value={configData.status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={styles.input}
                            placeholder="Enter status"
                          >
                            <option>Active</option>
                            <option>In-Active</option>
                          </Input>
                          <div style={styles.errorContainer}>
                            {errors.status && (
                              <small className="text-danger">
                                {errors.status}
                              </small>
                            )}
                          </div>
                        </div>
                      </FormGroup>
                      {/* <FormGroup style={styles.formField}>
                        <Label for="popular" style={styles.label}>
                         Popular
                        </Label>
                        <div style={styles.inputContainer}>
                          <Input
                            type="select"
                            id="popular"
                            name="popular"
                            className={errors.popular ? "is-invalid" : ""}
                            value={configData.popular}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={styles.input}
                            placeholder="Enter popular"
                          >
                            <option>Yes</option>
                            <option>No</option>
                          </Input>
                         
                          <div style={styles.errorContainer}>
                            {errors.popular && (
                              <small className="text-danger">
                                {errors.popular}
                              </small>
                            )}
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup style={styles.formField}>
                        <Label for="sequence" style={styles.label}>
                       Sequence
                        </Label>
                        <div style={styles.inputContainer}>
                          <Input
                            type="text"
                            id="sequence"
                            name="sequence"
                            className={errors.sequence ? "is-invalid" : ""}
                            value={configData.sequence}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={styles.input}
                            placeholder="Enter sequence"
                          />
                           
                          <div style={styles.errorContainer}>
                            {errors.sequence && (
                              <small className="text-danger">
                                {errors.sequence}
                              </small>
                            )}
                          </div>
                        </div>
                      </FormGroup> */}
                      <div className="d-flex justify-content-end">
                        <Button color="info" type="submit" onClick={submitData}>
                         Add Plan
                        </Button>
                      </div>
                    </Col>
                   
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>

        
        </Row>
      </Container>
    </>
  );
}

export default CreatePlan;
