import { getTenantDetails } from "action/plans";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import { cyanBlue } from "./constants/Constants";
import { GetOrgDetails } from "constants/ReducerConstants/Constants";

function OrgDetails() {
  const location = useLocation();
  const id = location.state.id;
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const tenantData = useSelector(
    (state) => state.TenantDetailsReducer.orgDetails
  );

  useEffect(() => {
    dispatch({ type: GetOrgDetails, payload: {} });
    setIsLoading(true);
    getTenantDetails(dispatch, id);
  }, [dispatch]);

  useEffect(() => {
    if (tenantData?.code) {
      if (tenantData?.code === 200) {
        setError(false);
        setIsLoading(false);
      } else {
        setError(true);
        setIsLoading(false);
      }
    } else {
      setError(false);
      setIsLoading(true);
    }
  }, [tenantData]);

  return (
    <>
      {isLoading ? (
        <div style={style.loaderContainer}>
          <i
            className="fa fa-spinner fa-spin fa-2xl"
            style={style.loaderColor}
          />
        </div>
      ) : error ? (
        <div style={style.loaderContainer}>
          <p style={style.loaderText}>Something went wrong</p>
        </div>
      ) : (
        <Col lg="12" md="12" sm="12">
          <Col lg="12" md="12" sm="12" className="border-bottom mb-2">
            <Row lg="12" md="12" sm="12" className="mb-3">
              <Label className="h3">Organization Details</Label>
            </Row>
            <Row lg="12" md="12" sm="12">
              <Col lg="6" md="6" sm="6">
                <Label className="form-control-label">Organization Name</Label>
                <p>
                  {tenantData.data?.organizationName
                    ? tenantData.data?.organizationName
                    : "NA"}
                </p>
              </Col>
              <Col lg="6" md="6" sm="6">
                <Label className="form-control-label">
                  Organization Website
                </Label>
                <a
                  href={`https://${tenantData.data?.websiteUrl}`}
                  target="_blank"
                >
                  <p>
                    {tenantData.data?.websiteUrl
                      ? tenantData.data?.websiteUrl
                      : "NA"}
                  </p>
                </a>
              </Col>
            </Row>
          </Col>
          <Col lg="12" md="12" sm="12">
            <Row lg="12" md="12" sm="12" className="my-3">
              <Label className="h3">Organization Address</Label>
            </Row>
            <Row lg="12" md="12" sm="12">
              <Col lg="6" md="6" sm="6">
                <Label className="form-control-label">Line One</Label>
                <p>
                  {tenantData.data?.lineOne ? tenantData.data?.lineOne : "NA"}
                </p>
                <Label className="form-control-label">Country</Label>
                <p>
                  {tenantData.data?.countryResponse.name
                    ? tenantData.data?.countryResponse.name
                    : "NA"}
                </p>
                <Label className="form-control-label">City</Label>
                <p>{tenantData.data?.city ? tenantData.data?.city : "NA"}</p>
              </Col>
              <Col lg="6" md="6" sm="6">
                <Label className="form-control-label">Line Two</Label>
                <p>
                  {tenantData.data?.lineTwo ? tenantData.data?.lineTwo : "NA"}
                </p>
                <Label className="form-control-label">State</Label>
                <p>{tenantData.data?.state ? tenantData.data?.state : "NA"}</p>
                <Label className="form-control-label">Postal Code</Label>
                <p>
                  {tenantData.data?.pincode ? tenantData.data?.pincode : "NA"}
                </p>
              </Col>
            </Row>
          </Col>
        </Col>
      )}
    </>
  );
}

export default OrgDetails;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
};
