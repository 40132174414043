import React from "react";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { Tabs } from "@mui/material";
import { Card, Col, Row, CardBody, Container } from "reactstrap";
import OrgDetails from "./OrgDetails";
import PlanDetails from "./PlanDetails";
import TenantListing from "./SubUserListing";
import PaymentHistory from "./PaymentHistory";
import CampaignListing from "./CampaignListing";
function DetailsTab() {
  const [value, setValue] = React.useState("ORGANIZATION_DETAILS");

  const handleTabChange = (e, value) => {
    setValue(value);
  };

  return (
    <>
      <SimpleHeader />
      <Container fluid className="mt--5">
        <Card>
          <CardBody>
            <Row lg="12" md="12" sm="12">
              <Col lg="12" md="12" sm="12">
                <Tabs
                  variant="fullWidth"
                  value={value}
                  onChange={handleTabChange}
                  className="border-bottom"
                >
                  <Tab
                    label="Organization Details"
                    value="ORGANIZATION_DETAILS"
                  />
                  <Tab label="Plan Details" value="PLAN_DETAILS" />
                  <Tab label="Campaigns" value="CAMPAIGN_LISTING" />
                  <Tab label="Sub-Users" value="TENANT_LISTING" />
                  <Tab label="Payment History" value="PAYMENT_HISTORY" />
                </Tabs>

                {value === "ORGANIZATION_DETAILS" && (
                  <Box className="p-4">
                    <OrgDetails />
                  </Box>
                )}
                {value === "PLAN_DETAILS" && (
                  <Box className="p-4">
                    <PlanDetails />
                  </Box>
                )}
                {value === "CAMPAIGN_LISTING" && (
                  <Box className="p-4">
                    <CampaignListing />
                  </Box>
                )}
                {value === "TENANT_LISTING" && (
                  <Box className="p-4">
                    <TenantListing />
                  </Box>
                )}
                {value === "PAYMENT_HISTORY" && (
                  <Box className="p-4">
                    <PaymentHistory />
                  </Box>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default DetailsTab;
