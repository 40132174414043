/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "pages/Auth/Login";
// import Register from "pages/Auth/Register";
import Dashboard from "pages/Dashboards/Dashboard";
import DetailsTab from "pages/Details/DetailsTab";
import CreatePlan from "pages/Plans/CreatePlan";
// import EditPlan from "pages/Plans/EditPlan";
import EditPlan from "pages/Plans/components/EditPlan";
import PlanList from "pages/Plans/PlanList";
import Tenants from "pages/TenantList/Tenants";

const routes = [
  {
    show: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    show: false,
    path: "/login",
    name: "Login",
    icon: "ni ni-ungroup text-orange",
    component: <Login />,
    layout: "/auth",
  },
  {
    show: true,
    path: "/plans",
    name: "Plans",
    icon: "ni ni-ungroup text-orange",
    component: <PlanList />,
    layout: "/admin",
  },
  {
    show: false,
    path: "/add/plan",
    name: "add",
    component: <CreatePlan />,
    layout: "/admin",
  },
  {
    // show: true,
    show: false,
    path: "/dashboard/tenentdetails",
    name: "Details",
    icon: "fa fa-book text-orange",
    component: <DetailsTab />,
    layout: "/admin",
  },
  {
    // show: true,
    show: false,
    path: "/plans/editplan",
    name: "Edit Plan",
    icon: "fa fa-pencil text-orange",
    component: <EditPlan />,
    layout: "/admin",
  },
  {
    show: true,
    path: "/tenants",
    name: "Tenants",
    icon: "fa-solid fa-users text-success",
    component: <Tenants />,
    layout: "/admin",
  },
];

export default routes;
