import React, { useEffect, useState } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Row, Label, Input, Button } from "reactstrap";
import Select from "react-select";
import { formatDateWithTime } from "./components/DateTime";
import { getSubuserList } from "action/plans";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomPagination from "./components/Pagination";
import { cyanBlue } from "./constants/Constants";

function TenantListing() {
  const data = useSelector((state) => state.TenantDetailsReducer.subuserList);
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.state.id;
  const [queryString, setQueryString] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);


  const columns = [
    {
      dataField: "ID",
      text: "Sr No",
      sort: true,
      headerAlign: "center",
      align: "center",
      formatter: (cell) => {
        return (
          <div className="mt-1">
            <span>{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      formatter: (cell) => {
        return (
          <div className="mt-1">
            <span>{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
      formatter: (cell) => {
        return (
          <div className="mt-1">
            <span>{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (cell) => {
        return (
          <div className="mt-1">
            <span>{cell}</span>
          </div>
        );
      },
    },
    {
      dataField: "createdAt",
      text: "Registered On",
      sort: true,

      formatter: (cell) => {
        return (
          <div className="mt-1">
            <span>{formatDateWithTime(cell)}</span>
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
  ];

  useEffect(() => {
    if (data) setIsLoading(false);
  }, [data]);

  useEffect(() => {
    getSubuserList(dispatch, id, queryString, pageNum, "");
  }, [dispatch, queryString]);


  return (
    <>
      <Col lg="12" md="12" sm="12">
        <Col lg="12" md="12" sm="12">
          <Row lg="12" md="12" sm="12">
            {isLoading ? (
              <div style={style.loaderContainer}>
                <i
                  className="fa fa-spinner fa-spin fa-2xl"
                  style={style.loaderColor}
                />
              </div>
            ) : (
              <>
                {data.totalRecords ? (
                  <ToolkitProvider
                    data={data.User}
                    keyField="ID"
                    columns={columns}
                    headerAlign="center"
                    search
                  >
                    {(props) => (
                      <div className="py-4 overflow-hidden table-responsive">
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <div style={style.loaderContainer}>
                    <p style={style.loaderText}>No Record Found</p>
                  </div>
                )}
              </>
            )}
          </Row>
        </Col>
        <CustomPagination
          totalPages={Math.ceil(data.totalRecords / data.noOfRecordsPerPage)}
          setPageNum={setPageNum}
          activePage={pageNum}
        />
      </Col>
    </>
  );
}

export default TenantListing;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
};
