import React, { useEffect, useState } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Col, Row, Label, Input, Button, Form } from "reactstrap";
import { getCampaignList } from "action/plans";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "./components/Pagination";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { cyanBlue } from "./constants/Constants";
import { UncontrolledTooltip } from "reactstrap";

function CampaignListing() {
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.state.id;
  const [queryString, setQueryString] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const sortString = `&sortOptionJSON={"sortBy":"ID","sortType":"desc"}`;
  const [searchData, setSearchData] = useState({
    Status: null,
    startDate: "",
    endDate: "",
  });
  const [statusOptions, setStatusoptions] = useState([
    { value: "PENDING", label: "PENDING" },
    { value: "COMPLETED", label: "COMPLETED" },
    { value: "DELETED", label: "DELETED" },
    { value: "IN-PROGRESS", label: "IN-PROGRESS" },
    { value: "CANCELLED", label: "CANCELLED" },
    { value: "FAILED", label: "FAILED" },
    { value: "MODIFIED", label: "MODIFIED" },
  ]);

  const campaignList = useSelector(
    (state) => state.TenantDetailsReducer.campaignList
  );

  useEffect(() => {
    if (campaignList) setIsLoading(false);
  }, [campaignList]);

  useEffect(() => {
    getCampaignList(dispatch, id, "", pageNum, sortString);
  }, [dispatch, pageNum]);

  useEffect(() => {
    if (queryString) {
      setPageNum(1);
      getCampaignList(dispatch, id, queryString, pageNum, sortString);
      setIsLoading(true);
    }
  }, [queryString]);

  const handleSearch = () => {
    const query = Object.keys(searchData)
      .map((key) => {
        if (key === "startDate" || key === "endDate") {
          return null;
        } else if (searchData[key]) {
          return {
            field: key,
            condition: "eq",
            filterValues: [searchData[key]],
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    if (searchData.startDate) {
      query.push({
        field: "StartAt",
        condition: "gt",
        filterValues: [searchData.startDate],
      });
    }

    if (searchData.endDate) {
      query.push({
        field: "EndAt",
        condition: "lt",
        filterValues: [searchData.endDate],
      });
    }

    if (queryString !== JSON.stringify(query)) {
      setQueryString(JSON.stringify(query));
    }
  };

  const handleClear = () => {
    setSearchData({
      Status: null,
      startDate: "",
      endDate: "",
    });
    setIsLoading(true);
    getCampaignList(dispatch, id, "", 1, sortString);
  };

  const handleStatusChange = (selectedOption) => {
    setSearchData((prev) => ({
      ...prev,
      Status: selectedOption,
    }));
  };

  const handleStartDateChange = (e) => {
    setSearchData((prevData) => ({ ...prevData, startDate: e.target.value }));
    if (
      searchData.endDate &&
      new Date(searchData.endDate) < new Date(e.target.value)
    ) {
      setSearchData((prevData) => ({ ...prevData, endDate: "" }));
    }
  };

  const handleEndDateChange = (e) => {
    setSearchData((prevData) => ({ ...prevData, endDate: e.target.value }));
  };

  return (
    <>
      <Col lg="12" md="12" sm="12">
        <Col lg="12" md="12" sm="12">
          <Row>
            <Select
              options={statusOptions}
              value={searchData.Status}
              onChange={(e) => handleStatusChange(e.value)}
              placeholder={
                searchData.Status ? searchData.Status : "Select Status"
              }
              styles={customStyles}
            />
            <Row className="d-flex align-items-center justify-content-start mx-4">
              <Label className="mt-2">Campaigns Created From</Label>
              <Input
                style={{ width: "140px", marginLeft: "5px", height: "40px" }}
                onChange={handleStartDateChange}
                value={searchData.startDate}
                type="date"
                id="startDate"
                name="startDate"
                // max={new Date().toISOString().split("T")[0]} // Set max date to today's date
              />
              <Label className="mx-2 mt-2">To</Label>
              <Input
                style={{ width: "140px", height: "40px" }}
                onChange={handleEndDateChange}
                value={searchData.endDate}
                type="date"
                id="endDate"
                name="endDate"
                min={searchData.startDate}
                // max={new Date().toISOString().split("T")[0]} // Set max date to today's date
              />
            </Row>

            <Row className="d-flex justify-content-start ml-2">
              <Button
                outline
                color="info"
                style={{ height: "40px", paddingTop: "8px" }}
                onClick={handleSearch}
              >
                Search
              </Button>
              <Button
                type="button"
                outline
                color="info"
                style={{ height: "40px", paddingTop: "8px" }}
                onClick={handleClear}
              >
                Clear
              </Button>
            </Row>
          </Row>
          <Row lg="12" md="12" sm="12">
            {isLoading ? (
              <div style={style.loaderContainer}>
                <i
                  className="fa fa-spinner fa-spin fa-2xl"
                  style={style.loaderColor}
                />
              </div>
            ) : (
              <>
                {campaignList?.code ? (
                  <div style={style.loaderContainer}>Something went wrong</div>
                ) : campaignList.totalRecords ? (
                  <ToolkitProvider
                    data={campaignList?.Campaign ? campaignList?.Campaign : []}
                    keyField="ID"
                    columns={[
                      {
                        dataField: "campaignName",
                        text: "campaign name",
                        sort: true,
                        formatter: (cell, row) => {
                          if (cell.length >= 20) {
                            let temp = cell.substring(0, 20) + "...";
                            return (
                              <>
                                <span id={`tooltipEdit${row.ID}`}>{temp}</span>
                                <UncontrolledTooltip
                                  target={`tooltipEdit${row.ID}`}
                                >
                                  {cell}
                                </UncontrolledTooltip>
                              </>
                            );
                          } else return cell;
                        },
                      },
                      {
                        dataField: "attendeeCount",
                        text: "total attendees",
                        sort: true,
                      },
                      {
                        dataField: "usedCredits",
                        text: "credits used",
                        sort: true,
                      },
                      {
                        dataField: "startAt",
                        text: "start date",
                        sort: true,
                      },
                      {
                        dataField: "endAt",
                        text: "end date",
                        sort: true,
                      },
                      {
                        dataField: "status",
                        text: "status",
                        sort: true,
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive overflow-hidden">
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <div style={style.loaderContainer}>
                    <p style={style.loaderText}>No Record Found</p>
                  </div>
                )}
              </>
            )}
          </Row>
          <CustomPagination
            totalPages={Math.ceil(
              campaignList.totalRecords / campaignList.noOfRecordsPerPage
            )}
            setPageNum={setPageNum}
            activePage={pageNum}
          />
        </Col>
      </Col>
    </>
  );
}

export default CampaignListing;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "14px", // Font size for the input
    borderColor: state.isFocused ? "#dee2e6" : "#dee2e6", // Border color when focused and unfocused
    boxShadow: state.isFocused ? "0 0 0 1px #dee2e6" : "none", // Remove default shadow on focus
    "&:hover": {
      borderColor: "#dee2e6", // Border color on hover
    },
    color: "##bfbbcb", // Text color for the input
    // backgroundColor: "lightgray", // Background color for the input
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "14px", // Font size for dropdown items
    color: "##bfbbcb", // Text color for the input
    // backgroundColor: "lightgray", // Background color for dropdown
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "##bfbbcb", // Text color for the input
  }),
};
