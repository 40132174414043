//Production
 export const BASE_URL = "https://console.bulkcalendar.com/core";

//Production
 export const REACT_APP_SITE_KEY = "6Ldy2kkqAAAAAON4Bd3OX6moK6w_RRHwgc8xDlqC";
 export const REACT_APP_SECRET_KEY = "6Ldy2kkqAAAAAH4I2AmAam74uudlyHWPbYt0oEAM";

//RoS
//export const BASE_URL = "https://bulkcalendar.rubaru.shop/core";

// export const BASE_URL = "http://localhost:3000/core";

//Ros
//export const REACT_APP_SITE_KEY = "6LcG2kkqAAAAAO1yASlufUxFDnhvfe5oH5vSr33O";
//export const REACT_APP_SECRET_KEY = "6LcG2kkqAAAAALdG6DNNPtXq-iAbI9n9D3ALNth-";

//Login url
export const USER_LOGIN = `${BASE_URL}/login`;

//Plan Api to get and update
export const PLAN = `${BASE_URL}/plan`;
// export const ADD_PLAN = `${BASE_URL}/plan/`;

//Get plan list
export const PLANS_LIST = `${BASE_URL}/plan/list`;

//Get tenant list
export const TENANT_LIST = `${BASE_URL}/tenants/list`;

//Update Plan
// export const UPDATE_PLAN = `${BASE_URL}/plan`;

//Get Country list
export const COUNTRY_LIST = `${BASE_URL}/country/list`;

//Get Tenant Details
export const TENANT_DETAILS = `${BASE_URL}/tenants/details`;

//Get Tenant Plan Details
export const PLAN_DETAILS = `${BASE_URL}/tenants/plan`;

//Get Tenant Campaign list
export const CAMPAIGNS_LIST = `${BASE_URL}/tenants/campaigns`;

//Get Tenant Subuser list
export const SUBUSER_LIST = `${BASE_URL}/tenants/sub-users`;

//Get Tenant Payment list
export const PAYMENT_LIST = `${BASE_URL}/payment/history`;

//Get Plans wise user count
export const PLAN_WISE_USERS = `${BASE_URL}/tenants/plan-wise-user-count`;

//Change tenant status
export const CHANGE_TENANT_STATUS = `${BASE_URL}/tenants`;
