import React, { useState } from "react";
import { Button, Col, Label, Row } from "reactstrap";
import AddCredits from "./components/AddCredits";
import { useDispatch, useSelector } from "react-redux";
import { getTenantPlanDetails } from "action/plans";
import { useLocation } from "react-router-dom";
import { cyanBlue } from "./constants/Constants";
import { GetPlanDetails } from "constants/ReducerConstants/Constants";

function PlanDetails() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const location = useLocation();
  const id = location.state.id;
  const dispatch = useDispatch();
  const tenantData = useSelector(
    (state) => state.TenantDetailsReducer.planDetails
  );

  React.useEffect(() => {
    if (tenantData?.code) {
      if (tenantData?.code === 200) {
        setError(false);
        setIsLoading(false);
      } else {
        setError(true);
        setIsLoading(false);
      }
    } else {
      setError(false);
      setIsLoading(true);
    }
  }, [tenantData]);

  React.useEffect(() => {
    dispatch({ type: GetPlanDetails, payload: {} });
    getTenantPlanDetails(dispatch, id);
  }, [dispatch]);

  const handlePlanType = (type) => {
    if (type === "STANDARD") return "Monthly";
    else return "Custom";
  };

  return (
    <>
      {isLoading ? (
        <div style={styles.loaderContainer}>
          <i
            className="fa fa-spinner fa-spin fa-2xl"
            style={styles.loaderColor}
          />
        </div>
      ) : error ? (
        <>
          <div style={styles.loaderContainer}>Something went wrong</div>
        </>
      ) : (
        <>
          <Col lg="12" md="12" sm="12">
            <Col lg="12" md="12" sm="12">
              <Row className="pb-2 justify-content-between">
                <h3>Plan Details</h3>
                <Button outline color="info" onClick={handleOpen}>
                  Add Credits
                </Button>
              </Row>
              <Row lg="12" md="12" sm="12">
                <Col lg="4" md="4" sm="4">
                  <Label className="form-control-label">Plan Name</Label>
                  <p>{tenantData.data.planName}</p>
                  <Label className="form-control-label">Total Credits</Label>
                  <p>{tenantData.data.allocatedCredits}</p>
                  <Label className="form-control-label">API Support</Label>
                  <p>{tenantData.data.apiAllowed}</p>
                </Col>
                <Col lg="4" md="4" sm="4">
                  <Label className="form-control-label">Billing Cycle</Label>
                  <p>{handlePlanType(tenantData.data.planType)}</p>
                  <Label className="form-control-label">Used Credits</Label>
                  <p>{tenantData.data.UsedCredits}</p>
                  <Label className="form-control-label">Last Renewed on</Label>
                  <p>{tenantData.data.planStartDate}</p>
                </Col>
                <Col lg="4" md="4" sm="4">
                  <Label className="form-control-label">Plan Cost</Label>
                  <p>₹ {tenantData.data.planInrRate}</p>
                  <Label className="form-control-label">
                    Remaining Credits
                  </Label>
                  <p>{tenantData.data.availableCredits}</p>
                  <Label className="form-control-label">Next Renewal on</Label>
                  <p>{tenantData.data.planRenewalDate}</p>
                </Col>
              </Row>
            </Col>
          </Col>
          <AddCredits handleClose={handleClose} isOpen={open} />
        </>
      )}
    </>
  );
}

export default PlanDetails;

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
};
