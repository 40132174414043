import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Button,
  Form,
  Modal as RSModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "reactstrap";
import Swal from "sweetalert2";

function AddCredits({ handleClose, isOpen }) {
  const [credits, setCredits] = useState(1000);
  const [creditsCost, setCreditsCost] = useState(0);
  const [err, setErr] = useState(false);
  const [isValid, setIsValid] = useState(true); // New state to track validity
  let remaining = 7000;

  useEffect(() => {
    if (credits >= 1000 && credits % 1000 === 0) {
      setCreditsCost(credits / 100);
      setIsValid(true); // Set valid if conditions are met
    } else {
      setIsValid(false); // Set invalid if conditions are not met
    }
  }, [credits]);

  useEffect(() => {
    if (err) {
      setErr(false);
      setCredits(1000);
      setCreditsCost(0);
      setIsValid(true); // Reset validity on close
    }
  }, [isOpen]);

  const handleInput = (e) => {
    let temp = e.target.value;
    setCredits(temp);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const temp = parseInt(credits);
    if (temp % 1000 === 0 && temp !== 0) {
      handleClose();
      //Swal is for popup message can be called as SweetAlert
      Swal.fire({
        title: "Credits have been added.",
        text: `${credits} credits, valued at $${creditsCost}, have been credited to your account`,
        icon: "success",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "bg-success",
        },
      });
      setCredits(1000);
      setCreditsCost(0);
    } else {
      setErr(true);
    }
  };

  const handleTotal = () => {
    let temp = parseInt(credits) + parseInt(remaining);
    let err = "Not Valid";
    if (temp % 1000 === 0) return temp;
    else return err;
  };

  return (
    <>
      <RSModal isOpen={isOpen} toggle={handleClose} centered>
        <ModalHeader toggle={handleClose}>Add Credits</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Label tag={"h5"}>Last recharged on 30 Sep 2024</Label>
                  </Col>
                </Row>
                <Input
                  type="number"
                  value={credits}
                  onChange={handleInput}
                  onBlur={handleSubmit}
                  placeholder="Add Credits"
                  className={err ? "border-danger w-50" : "border w-50 "}
                />
                {err && (
                  <Label className="text-danger form-control-plaintext">
                    Please ensure that the credits are in multiples of 1000.
                  </Label>
                )}

                <Label className="p-2 form-control-plaintext">
                  Please enter values in multiples of 1000.
                </Label>
                <Card className="m-0">
                  <CardBody>
                    <CardHeader className="px-0 py-2">
                      <strong>Total Cost ${creditsCost}</strong>
                    </CardHeader>
                    <Col className="py-2">
                      <Row className="d-flex justify-content-between">
                        <Label>Current Credits&nbsp;</Label>
                        <Label>{remaining}</Label>
                      </Row>
                      <Row className="d-flex justify-content-between">
                        <Label>Adding Credits&nbsp;</Label>
                        <Label>{credits}</Label>
                      </Row>
                    </Col>
                    <CardFooter className="px-3 py-2">
                      <Row className="d-flex justify-content-between">
                        <Label>Total Credits&nbsp;</Label>
                        <Label>{handleTotal()}</Label>
                      </Row>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="gray" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="info"
            type="submit"
            onClick={handleSubmit}
            disabled={!isValid} // Disable button if not valid
          >
            Add Credits
          </Button>
        </ModalFooter>
      </RSModal>
    </>
  );
}

export default AddCredits;
