import axios from "axios";
import {
  PLAN,
  BASE_URL,
  PLANS_LIST,
  TENANT_LIST,
  COUNTRY_LIST,
  TENANT_DETAILS,
  PLAN_DETAILS,
  CAMPAIGNS_LIST,
  SUBUSER_LIST,
  PAYMENT_LIST,
  PLAN_WISE_USERS,
  CHANGE_TENANT_STATUS,
} from "./apiConstants";

const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("p")) {
    req.headers.token = JSON.parse(localStorage.getItem("p")).token;
  }
  return req;
});

//get option value List
export const add_plan = (planData) => API.post(`${PLAN}`, planData);

export const get_plan = (planid) => API.get(`${PLAN}/${planid}`);

export const get_plans_list = () => API.get(`${PLANS_LIST}`);

export const get_tenant_list = (query, num,sort) =>
  API.get(
    `${TENANT_LIST}?${
      query && `filtersJSON=${query}`
    }&page=${num}${sort}`
  );

export const update_plan = (planID, planUpdatedData) =>
  API.patch(`${PLAN}/${planID}`, planUpdatedData);

export const get_country_list = () => API.get(`${COUNTRY_LIST}`);

export const get_tenant_details = (id) => API.get(`${TENANT_DETAILS}/${id}`);

export const get_tenant_plan_details = (id) => API.get(`${PLAN_DETAILS}/${id}`);

export const get_campaign_list = (id, filter, pageNum, sort) =>
  API.get(
    `${CAMPAIGNS_LIST}/${id}?${
      filter && `filtersJSON=${filter}`
    }&page=${pageNum}${sort}`
  );

export const get_subuser_list = (id, filter, pageNum, sort) =>
  API.get(
    `${SUBUSER_LIST}/${id}?filtersJSON=${filter}&page=${pageNum}&sortOptionJSON=${sort}`
  );

export const get_payment_list = (id, filter, pageNum, sort) =>
  API.get(
    `${PAYMENT_LIST}?tenantID=${id}&filtersJSON=${filter}&page=${pageNum}&sortOptionJSON=${sort}`
  );

export const get_plan_wise_users = () => API.get(`${PLAN_WISE_USERS}`);

export const change_tenant_status = (id) =>
  API.patch(`${CHANGE_TENANT_STATUS}/${id}`);
