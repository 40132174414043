import React, { useState, useEffect } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { formatDateWithTime } from "./components/DateTime";
import { Col, Row, Label, Input, Button } from "reactstrap";
import Select from "react-select";
import { getSubuserList } from "action/plans";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getPaymentList } from "action/plans";
import { cyanBlue } from "./constants/Constants";
import CustomPagination from "./components/Pagination";
function PaymentHistory() {
  const data = useSelector((state) => state.TenantDetailsReducer.paymentList);
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.state.id;
  const [queryString, setQueryString] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchData, setSearchData] = useState({
    Status: null,
    startDate: "",
    endDate: "",
  });

  // const [statusOptions, setStatusoptions] = useState([
  //   { value: "ACTIVE", label: "ACTIVE" },
  //   { value: "IN-ACTIVE", label: "IN-ACTIVE" },
  // ]);

  const columns = [
    {
      dataField: "planName",
      text: "planName",
      sort: true,
      headerAlign: "center",
      align: "center",
      style: { width: "250px" },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      style: { width: "250px" },
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "allocatedCredits",
      text: "allocated Credits",
      sort: true,
      style: { width: "250px" },
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "createdAt",
      text: "created At",
      sort: true,
      style: { width: "300px" },
      headerAlign: "center",
      align: "center",
    },
    // {
    //   dataField: "credits",
    //   text: "CREDITS",
    //   sort: true,
    //   style: { width: "150px" },

    //   formatter: (cell) => {
    //     return (
    //       <div className="d-flex justify-content-start align-items-center">
    //         {cell}
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "pdflink",
      text: "DOWNLOAD INVOICE",
      sort: true,
      headerAlign: "center",
      align: "center",
      style: {
        width: "70px",
      },
      formatter: (cell) => {
        return (
          <a href={cell}>
            <i className="fa fa-download text-lg text-info"></i>
          </a>
        );
      },
    },
  ];

  useEffect(() => {
    getPaymentList(dispatch, id, queryString, pageNum, "");
  }, [dispatch, queryString]);

  useEffect(() => {
    if (data) setIsLoading(false);
  }, [data]);

  const handleSearch = () => {
    const query = Object.keys(searchData)
      .map((key) => {
        if (key === "startDate" || key === "endDate") {
          return null;
        } else if (searchData[key]) {
          return {
            field: key,
            condition: "eq",
            filterValues: [searchData[key]],
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    if (searchData.startDate && searchData.endDate) {
      query.push({
        field: "CreatedAt",
        condition: "btw",
        filterValues: [searchData.startDate, searchData.endDate],
      });
    } else {
      if (searchData.startDate) {
        query.push({
          field: "CreatedAt",
          condition: "gt",
          filterValues: [searchData.startDate],
        });
      }

      if (searchData.endDate) {
        query.push({
          field: "CreatedAt",
          condition: "lt",
          filterValues: [searchData.endDate],
        });
      }
    }
    setIsLoading(true);
    setQueryString(JSON.stringify(query));
    setPageNum(1);
  };

  const handleClear = () => {
    setSearchData({
      Status: null,
      startDate: "",
      endDate: "",
    });
    setIsLoading(true);
    getPaymentList(dispatch, id, "", 1, "");
  };

  // const handleStatusChange = (selectedOption) => {
  //   setSearchData((prev) => ({
  //     ...prev,
  //     Status: selectedOption,
  //   }));
  // };

  const handleStartDateChange = (e) => {
    setSearchData((prevData) => ({ ...prevData, startDate: e.target.value }));
    if (
      searchData.endDate &&
      new Date(searchData.endDate) < new Date(e.target.value)
    ) {
      setSearchData((prevData) => ({ ...prevData, endDate: "" }));
    }
  };

  const handleEndDateChange = (e) => {
    setSearchData((prevData) => ({ ...prevData, endDate: e.target.value }));
  };

  return (
    <>
      <Col lg="12" md="12" sm="12">
        <Col lg="12" md="12" sm="12">
          <Row lg="12" md="12" sm="12">
            {isLoading ? (
              <div style={style.loaderContainer}>
                <i
                  className="fa fa-spinner fa-spin fa-2xl"
                  style={style.loaderColor}
                />
              </div>
            ) : (
              <>
                {data.totalRecords ? (
                  <ToolkitProvider
                    data={data.listPaymentHistory}
                    keyField="ID"
                    columns={columns}
                    headerAlign="center"
                    search
                  >
                    {(props) => (
                      <div className="py-4 overflow-hidden table-responsive">
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <div style={style.loaderContainer}>
                    <p style={style.loaderText}>No Record Found</p>
                  </div>
                )}
              </>
            )}
          </Row>
          <CustomPagination
            totalPages={Math.ceil(data.totalRecords / data.noOfRecordsPerPage)}
            setPageNum={setPageNum}
            activePage={pageNum}
          />
        </Col>
      </Col>
    </>
  );
}

export default PaymentHistory;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
};
