import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useNavigate } from "react-router-dom";
import { getTenantList } from "action/plans";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "pages/Details/components/Pagination";
import FilterOptions from "./FilterOptions";
import { cyanBlue } from "pages/Details/constants/Constants";

function TenantList() {
  const sortParams = `&sortOptionJSON={"sortBy":"ID","sortType":"desc"}`;
  const [queryString, setQueryString] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const tenantData = useSelector((state) => state.TenantReducer.tenantList);
  // const [searchData, setSearchData] = useState({
  //   planName: "",
  //   status: "",
  //   countryName: "",
  //   startDate: "",
  //   endDate: "",
  //   email: "",
  //   name: "",
  // });

  React.useEffect(() => {
    if (tenantData?.code) {
      if (tenantData?.code === 200) {
        setError(false);
        setIsLoading(false);
      } else {
        setError(true);
        setIsLoading(false);
      }
    } else {
      setError(false);
      setIsLoading(true);
    }
  }, [tenantData]);

  React.useEffect(() => {
    getTenantList(dispatch, queryString, pageNum, sortParams);
  }, [dispatch, pageNum]);

  // const statusCellRender = (cell) => {
  //   return (
  //     <span style={{ color: `${cell === "ACTIVE" ? "green" : "red"}` }}>
  //       {cell}
  //     </span>
  //   );
  // };

  const handleCountry = (value) => {
    if (value?.name) return value.name;
    else return <div className="ml-3">-</div>;
  };

  // Working code for  filters

  // const handlePlanChange = (selectedOption) => {
  //   setSearchData((prevData) => ({
  //     ...prevData,
  //     planName: selectedOption?.value || "",
  //   }));
  // };

  // const handleStatusChange = (selectedOption) => {
  //   setSearchData((prevData) => ({
  //     ...prevData,
  //     status: selectedOption?.value || "",
  //   }));
  // };

  // const handleNameChange = (input) => {
  //   setSearchData((prev) => ({ ...prev, name: input.target.value }));
  // };

  // const handleEmailChange = (input) => {
  //   setSearchData((prev) => ({ ...prev, email: input.target.value }));
  // };

  // const handleCountryNameChange = (selectedOption) => {
  //   setSearchData((prevData) => ({
  //     ...prevData,
  //     countryName: selectedOption?.value || "",
  //   }));
  // };

  // const handleStartDateChange = (e) => {
  //   setSearchData((prevData) => ({ ...prevData, startDate: e.target.value }));
  //   if (
  //     searchData.endDate &&
  //     new Date(searchData.endDate) < new Date(e.target.value)
  //   ) {
  //     setSearchData((prevData) => ({ ...prevData, endDate: "" }));
  //   }
  // };

  // const handleEndDateChange = (e) => {
  //   setSearchData((prevData) => ({ ...prevData, endDate: e.target.value }));
  // };

  // const handleSearch = () => {
  //   const query = Object.keys(searchData)
  //     .map((key) => {
  //       if (key === "startDate" || key === "endDate") {
  //         return null;
  //       } else if (searchData[key]) {
  //         return {
  //           field: key,
  //           condition: key !== "status" ? "like" : "eq",
  //           filterValues: [searchData[key]],
  //         };
  //       }
  //       return null;
  //     })
  //     .filter((item) => item !== null);

  //   if (searchData.startDate && searchData.endDate) {
  //     query.push({
  //       field: "registeredDate",
  //       condition: "btw",
  //       filterValues: [searchData.startDate, searchData.endDate],
  //     });
  //   } else if (searchData.startDate && !searchData.endDate) {
  //     query.push({
  //       field: "registeredDate",
  //       condition: "gt",
  //       filterValues: [searchData.startDate],
  //     });
  //   } else if (!searchData.startDate && searchData.endDate) {
  //     query.push({
  //       field: "registeredDate",
  //       condition: "lt",
  //       filterValues: [searchData.endDate],
  //     });
  //   }
  //   if (queryString !== JSON.stringify(query)) {
  //     setIsLoading(true);
  //     setQueryString(JSON.stringify(query));
  //     setPageNum(1);
  //     getTenantList(dispatch, JSON.stringify(query), pageNum, sortParams);
  //   }
  // };

  // const handleClear = () => {
  //   setSearchData({
  //     planName: "",
  //     status: "",
  //     countryName: "",
  //     startDate: "",
  //     endDate: "",
  //     email: "",
  //     name: "",
  //   });
  //   setIsLoading(true);
  //   setQueryString("");
  //   getTenantList(dispatch, [], 1, sortParams);
  // };

  const handleStatus = (cell, row) => {
    switch (cell) {
      case "ACTIVE": {
        return (
          <div>
            <i
              className="fa-solid fa-user-check text-success "
              aria-hidden="true"
              id={`tooltipEdit${row.ID}`}
            ></i>
            <UncontrolledTooltip target={`tooltipEdit${row.ID}`}>
              Active
            </UncontrolledTooltip>
          </div>
        );
      }
      case "INACTIVE": {
        return (
          <div>
            <i
              id={`tooltipEdit${row.ID}`}
              className="fa-solid fa-user-xmark text-danger "
            ></i>
            <UncontrolledTooltip target={`tooltipEdit${row.ID}`}>
              Inactive
            </UncontrolledTooltip>
          </div>
        );
      }
      case "NOT_VERIFIED": {
        return (
          <div>
            <i
              id={`tooltipEdit${row.ID}`}
              className="fa-solid fa-user-slash text-warning "
            ></i>
            <UncontrolledTooltip target={`tooltipEdit${row.ID}`}>
              Not Verified
            </UncontrolledTooltip>
          </div>
        );
      }
      case "DELETED": {
        return (
          <div>
            <i
              id={`tooltipEdit${row.ID}`}
              className="fa-solid fa-user-minus text-danger"
            ></i>
            <UncontrolledTooltip target={`tooltipEdit${row.ID}`}>
              Deleted
            </UncontrolledTooltip>
          </div>
        );
      }
      default:
        return cell;
    }
  };
  return (
    <>
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row md={12} lg={12} sm={12}>
              <Col md={10} lg={10} sm={10}>
                <h3 className="mb-0 h3 d-flex">
                  Tenant List{" "}
                  {tenantData?.data?.totalRecords && (
                    <h3 className="mb-0 h3">
                      &nbsp;({tenantData?.data?.totalRecords})
                    </h3>
                  )}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {
              isLoading ? (
                <div style={style.loaderContainer}>
                  <i
                    className="fa fa-spinner fa-spin fa-2xl"
                    style={style.loaderColor}
                  />
                </div>
              ) : error ? (
                <>
                  <div style={style.loaderContainer}>Something went wrong</div>
                </>
              ) : (
                <>
                  <ToolkitProvider
                    data={
                      tenantData?.data.tenants ? tenantData?.data.tenants : []
                    }
                    keyField="ID"
                    columns={[
                      {
                        dataField: "organizationName",
                        text: "Organization",
                        sort: false,
                        formatter: (cell) => {
                          if (cell === "")
                            return <div className="text-center h-0">- </div>;
                          else return cell;
                        },
                      },
                      {
                        dataField: "firstName",
                        text: "Name",
                        sort: false,
                        formatter: (cell, row) => {
                          if (row.firstName === "" && row.lastName === "")
                            return <div className="text-center h-0">- </div>;
                          else return row.firstName.concat(" ", row.lastName);
                        },
                      },
                      {
                        dataField: "email",
                        text: "Email",
                        sort: false,
                        formatter: (cell) => {
                          if (cell === "")
                            return <div className="text-center h-0">- </div>;
                          else return cell;
                        },
                      },
                      {
                        dataField: "plan",
                        text: "Plan",
                        sort: true,
                        formatter: (cell) => {
                          if (cell === "")
                            return <div className="text-center h-0">- </div>;
                          else return cell;
                        },
                      },
                      {
                        dataField: "registeredDate",
                        text: "Registered Date",
                        sort: true,
                        formatter: (cell) => {
                          if (cell === "")
                            return <div className="text-center h-0">- </div>;
                          else return cell;
                        },
                      },
                      {
                        dataField: "countryResponse",
                        text: "Country",
                        sort: true,
                        formatter: (cell) => handleCountry(cell),
                      },
                      {
                        dataField: "totalCampaign",
                        text: "Total Campaign",
                        sort: true,
                        formatter: (cell) => {
                          if (cell === "")
                            return <div className="text-center h-0">- </div>;
                          else return cell;
                        },
                      },
                      {
                        dataField: "creditSpent",
                        text: "Credits Spent",
                        sort: true,
                        formatter: (cell) => {
                          if (cell === "")
                            return <div className="text-center h-0">- </div>;
                          else return cell;
                        },
                      },
                      {
                        dataField: "subUsers",
                        text: "Sub Users",
                        sort: true,
                        formatter: (cell) => {
                          if (cell === "")
                            return <div className="text-center h-0">- </div>;
                          else return cell;
                        },
                      },
                      {
                        dataField: "status",
                        text: "Status",
                        sort: true,
                        align: "center",
                        formatter: (cell, row) => {
                          return handleStatus(cell, row);
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          rowEvents={{
                            onClick: (e, row) => {
                              navigate(`/admin/dashboard/tenentdetails`, {
                                state: { id: row.ID },
                              });
                            },
                          }}
                          rowStyle={{ cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                  <CustomPagination
                    totalPages={Math.ceil(
                      tenantData.data.totalRecords /
                        tenantData.data.noOfRecordsPerPage
                    )}
                    setPageNum={setPageNum}
                    activePage={pageNum}
                  />
                </>
              )
              // {tenantData?.code ? (
              //   <div style={style.loaderContainer}>Something went wrong</div>
              // ) : tenantData.tenants ? (
              //   // <>
              //   //   <ToolkitProvider
              //   //     data={tenantData?.tenants ? tenantData.tenants : []}
              //   //     keyField="ID"
              //   //     columns={[
              //   //       {
              //   //         dataField: "organizationName",
              //   //         text: "Organization",
              //   //         sort: false,
              //   //         formatter: (cell) => {
              //   //           if (cell === "")
              //   //             return <div className="text-center h-0">- </div>;
              //   //           else return cell;
              //   //         },
              //   //       },
              //   //       {
              //   //         dataField: "firstName",
              //   //         text: "Name",
              //   //         sort: false,
              //   //         formatter: (cell, row) => {
              //   //           if (row.firstName === "" && row.lastName === "")
              //   //             return <div className="text-center h-0">- </div>;
              //   //           else return row.firstName.concat(" ", row.lastName);
              //   //         },
              //   //       },
              //   //       {
              //   //         dataField: "email",
              //   //         text: "Email",
              //   //         sort: false,
              //   //         formatter: (cell) => {
              //   //           if (cell === "")
              //   //             return <div className="text-center h-0">- </div>;
              //   //           else return cell;
              //   //         },
              //   //       },
              //   //       {
              //   //         dataField: "plan",
              //   //         text: "Plan",
              //   //         sort: true,
              //   //         formatter: (cell) => {
              //   //           if (cell === "")
              //   //             return <div className="text-center h-0">- </div>;
              //   //           else return cell;
              //   //         },
              //   //       },
              //   //       {
              //   //         dataField: "registeredDate",
              //   //         text: "Registered Date",
              //   //         sort: true,
              //   //         formatter: (cell) => {
              //   //           if (cell === "")
              //   //             return <div className="text-center h-0">- </div>;
              //   //           else return cell;
              //   //         },
              //   //       },
              //   //       {
              //   //         dataField: "countryResponse",
              //   //         text: "Country",
              //   //         sort: true,
              //   //         formatter: (cell) => handleCountry(cell),
              //   //       },
              //   //       {
              //   //         dataField: "totalCampaign",
              //   //         text: "Total Campaign",
              //   //         sort: true,
              //   //         formatter: (cell) => {
              //   //           if (cell === "")
              //   //             return <div className="text-center h-0">- </div>;
              //   //           else return cell;
              //   //         },
              //   //       },
              //   //       {
              //   //         dataField: "creditSpent",
              //   //         text: "Credits Spent",
              //   //         sort: true,
              //   //         formatter: (cell) => {
              //   //           if (cell === "")
              //   //             return <div className="text-center h-0">- </div>;
              //   //           else return cell;
              //   //         },
              //   //       },
              //   //       {
              //   //         dataField: "subUsers",
              //   //         text: "Sub Users",
              //   //         sort: true,
              //   //         formatter: (cell) => {
              //   //           if (cell === "")
              //   //             return <div className="text-center h-0">- </div>;
              //   //           else return cell;
              //   //         },
              //   //       },
              //   //       {
              //   //         dataField: "status",
              //   //         text: "Status",
              //   //         sort: true,
              //   //         align: "center",
              //   //         formatter: (cell) => {
              //   //           return handleStatus(cell);
              //   //         },
              //   //       },
              //   //     ]}
              //   //     search
              //   //   >
              //   //     {(props) => (
              //   //       <div className="py-4 table-responsive">
              //   //         <BootstrapTable
              //   //           {...props.baseProps}
              //   //           bootstrap4={true}
              //   //           bordered={false}
              //   //           rowEvents={{
              //   //             onClick: (e, row) => {
              //   //               navigate(`/admin/dashboard/tenentdetails`, {
              //   //                 state: { id: row.ID },
              //   //               });
              //   //             },
              //   //           }}
              //   //           rowStyle={{ cursor: "pointer" }}
              //   //         />
              //   //       </div>
              //   //     )}
              //   //   </ToolkitProvider>
              //   //   <CustomPagination
              //   //     totalPages={Math.ceil(
              //   //       tenantData.totalRecords / tenantData.noOfRecordsPerPage
              //   //     )}
              //   //     setPageNum={setPageNum}
              //   //     activePage={pageNum}
              //   //   />
              //   // </>
              // ) : (
              //   <div style={style.loaderContainer}>
              //     <p style={style.loaderText}>No Record Found</p>
              //   </div>
              // )}
            }
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default TenantList;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
};
