export function formatDateWithTime(inputDateTime) {
  // Parse the input date string into a Date object
  const date = new Date(inputDateTime);
  // Get day, month, year, hour, minute, and second components
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  // const seconds = date.getSeconds();
  // Get the current year
  const currentYear = new Date().getFullYear();
  // Define an array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  // Format the date in the desired format: dd MonthName yyyy hh:mm:ss AM/PM
  let formattedDate = `${day} ${monthNames[monthIndex]},`;
  // Add year to formatted date if it's not the current year
  if (!inputDateTime.includes(",")) {
    formattedDate += ` ${year}`;
  }
  formattedDate += ` ${hours % 12 || 12}:${minutes < 10 ? "0" : ""}${minutes} ${
    hours < 12 ? "AM" : "PM"
  }`;
  return formattedDate;
}
